<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    aria-labelledby="logoutModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="logoutModalLabel">Confirm</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">You are about to <b>Logout</b>, continue?</div>
        <div class="modal-footer mx-auto">
          <button
            type="button"
            class="btn btn-secondary p-1 px-2"
            data-bs-dismiss="modal"
          >
            cancel
          </button>
          <button
            type="button"
            class="btn btn-danger p-1 px-4"
            @click="auth.logout()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      auth: this.$auth,
    };
  },
};
</script>
