import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      isAuthenticated: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      isAuthenticated: false,
    },
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/RegisterView.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      isAuthenticated: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: {
      isAuthenticated: true,
      roles: ["customer"],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ProductsView.vue"),
  },
  {
    path: "/my-products",
    name: "my-products",
    meta: {
      isAuthenticated: true,
      roles: ["seller"],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/MyProductsView.vue"),
  },
  {
    path: "/my-cart",
    name: "my-cart",
    meta: {
      isAuthenticated: true,
      roles: ["customer"],
    },
    component: () =>
      import(/* webpackChunkName: "my-cart" */ "../views/MyCartView.vue"),
  },
  {
    path: "/my-orders",
    name: "my-orders",
    meta: {
      isAuthenticated: true,
      roles: ["customer"],
    },
    component: () =>
      import(/* webpackChunkName: "my-orders" */ "../views/MyOrders.vue"),
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    meta: {
      isAuthenticated: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ "../views/Unauthorized.vue"
      ),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
