import { AUTH_SERVICE_URL, AUTH_CLIENT_ID, AUTH_REALM } from "@/config";

const AUTH_URL = `${AUTH_SERVICE_URL}realms/${AUTH_REALM}`;
const ACCESS_TOKEN_STRING = "e-shop-accessToken";
const REFRESH_TOKEN_STRING = "e-shop-refreshToken";
const login = async (username, password) => {
  const response = await fetch(`${AUTH_URL}/protocol/openid-connect/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "password",
      client_id: AUTH_CLIENT_ID,
      username: username,
      password: password,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    const accessToken = data.access_token;
    const refreshToken = data.refresh_token;

    localStorage.setItem(ACCESS_TOKEN_STRING, accessToken);
    localStorage.setItem(REFRESH_TOKEN_STRING, refreshToken);

    return { accessToken, refreshToken };
  } else {
    const errorData = await response.json();
    throw new Error(
      `Failed to authenticate: ${
        errorData.error_description || "Unknown error"
      }`
    );
  }
};

const logout = async (redirectURL = "/") => {
  try {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STRING);

    if (!refreshToken) {
      console.warn("Refresh token not found while login out");
      window.location.href = redirectURL;
    }

    // Call Keycloak's logout endpoint passing the refresh token
    const response = await fetch(`${AUTH_URL}/protocol/openid-connect/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: AUTH_CLIENT_ID,
        refresh_token: refreshToken,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to logout from Keycloak");
    }

    // Clear tokens from localStorage
    localStorage.removeItem(ACCESS_TOKEN_STRING);
    localStorage.removeItem(REFRESH_TOKEN_STRING);

    // Redirect to the specified URL or perform other post-logout actions
    window.location.href = redirectURL;
  } catch (error) {
    console.error("Logout error:", error.message);
    // Handle logout error as needed
  }
};

const getRefreshTokenFromStorage = () => {
  return localStorage.getItem(REFRESH_TOKEN_STRING);
};
const token = () => {
  return localStorage.getItem(ACCESS_TOKEN_STRING);
};

const getUserInfoFromToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STRING);

  if (!accessToken) {
    throw new Error("Access token not found");
  }

  try {
    const decodedToken = JSON.parse(atob(accessToken.split(".")[1])); // Decoding the token payload
    return {
      username: decodedToken.preferred_username || null,
      email: decodedToken.email || null,
      realmRoles: decodedToken.realm_access?.roles || [],
      exp: decodedToken.exp,
      expiryMs: decodedToken.exp * 1000, // Convert expiration time to milliseconds
      // Add other user attributes or claims as needed
    };
  } catch (error) {
    throw new Error("Failed to parse access token");
  }
};

const hasResourceRole = (roles) => {
  const userRoles = getUserInfoFromToken().realmRoles || [];

  // Check if the user has any of the specified roles
  return roles.some((role) => userRoles.includes(role));
};

const isAuthenticated = () => {
  if (!token()) {
    return false;
  }
  const userInfo = getUserInfoFromToken();
  const isTokenExpired = Math.ceil(Date.now() / 1000) >= userInfo.exp;
  return !isTokenExpired; // User is authenticated if the token is not expired
};

const refreshAccessToken = async () => {
  const refreshToken = getRefreshTokenFromStorage();

  if (!refreshToken) {
    throw new Error("Refresh token not found in localStorage");
  }

  const response = await fetch(`${AUTH_URL}/protocol/openid-connect/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: AUTH_CLIENT_ID,
      refresh_token: refreshToken,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    const newAccessToken = data.access_token;
    const newRefreshToken = data.refresh_token;

    localStorage.setItem(ACCESS_TOKEN_STRING, newAccessToken);
    localStorage.setItem(REFRESH_TOKEN_STRING, newRefreshToken);

    return { newAccessToken, newRefreshToken };
  } else {
    throw new Error("Failed to refresh token");
  }
};
const auth = {
  login,
  logout,
  refreshAccessToken,
  token,
  getUserInfoFromToken,
  isAuthenticated,
  hasResourceRole,
};

export default auth;
