import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import authentication from "./plugins/authentication";

export const app = createApp(App);
app.use(authentication, { router });
app.use(router);
app.mount("#app");

setInterval(async () => {
  if (app.config.globalProperties.$auth.isAuthenticated()) {
    const { newAccessToken } =
      await app.config.globalProperties.$auth.refreshAccessToken();

    if (newAccessToken) {
      console.debug("Token refreshed: " + newAccessToken);
      const remainingTime = Math.round(
        app.config.globalProperties.$auth.getUserInfoFromToken().exp -
          Math.round(new Date().getTime() / 1000)
      );
      console.log(`Token refreshed, valid for ${remainingTime} seconds`);
    } else {
      console.warn("Token not refreshed. prolly an error!!");
    }
  } else {
    console.warn("Token not refreshed. User not authenticated!!");
  }
}, 60000 * 5); // every 5 mins
