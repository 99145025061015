import auth from "../service/auth";

export default {
  install: (app, options) => {
    app.config.globalProperties.$auth = auth;
    options.router.beforeEach(async (to, from) => {
      console.debug(to, from);
      const { isAuthenticated } = to.meta;

      if (!isAuthenticated) {
        // Page does not require authentication
        return true;
      }

      if (!app.config.globalProperties.$auth.isAuthenticated()) {
        // except login page
        if (to.name === "Login") {
          return true;
        }
        // User not authenticated, force login
        return { name: "Login" };
      }
      if (!to.meta.roles) {
        // user authenticated. Access roles not defined. User allowed
        return true;
      }

      if (app.config.globalProperties.$auth.hasResourceRole(to.meta.roles)) {
        // User authenticated and authorized
        return true;
      }

      // User authenticated but not authorized, redirect to unauthorized page
      return { name: "Unauthorized" };
    });
  },
};
