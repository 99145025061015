<template>
  <nav>
    <router-link :to="{ name: 'home' }">Home</router-link> |
    <span v-if="$auth.isAuthenticated()">
      <span v-show="$auth.hasResourceRole(['customer'])">
        <router-link :to="{ name: 'products' }">Products</router-link> |
      </span>
      <span v-show="$auth.hasResourceRole(['seller'])"
        ><router-link :to="{ name: 'my-products' }"
          ><i class="bi bi-shop"></i>My products</router-link
        >
        |</span
      >
      <span v-show="$auth.hasResourceRole(['customer'])">
        <router-link :to="{ name: 'my-cart' }"
          ><i class="bi bi-cart3"></i>My cart</router-link
        >
        |
      </span>
      <span v-show="$auth.hasResourceRole(['customer'])">
        <router-link :to="{ name: 'my-orders' }"
          ><i class="bi bi-bag-dash"></i>My Orders</router-link
        >
        |
      </span>
      <button
        type="button"
        class="btn btn btn-link"
        data-bs-toggle="modal"
        data-bs-target="#logoutModal"
      >
        <i class="bi bi-person"></i>{{ $auth.getUserInfoFromToken().username }}
      </button>
    </span>
    <span v-else>
      <router-link :to="{ name: 'Login' }">Sign In</router-link>
    </span>
  </nav>
  <ConfirmLogoutModal />
</template>
<script>
import ConfirmLogoutModal from "./ConfirmLogoutModal.vue";
export default {
  name: "NavigationBar",
  components: { ConfirmLogoutModal },
  props: {},
  data() {
    return {};
  },
};
</script>
