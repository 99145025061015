// Get the default host URL dynamically
const getDefaultHost = (suffix) => {
  const protocol = window.location.protocol;
  const currentHost = window.location.host;
  return `${protocol}//${currentHost}/${suffix}/`;
};

// Set default URLs if no environment variable is provided, using the default host
export const DEFAULT_HOST = getDefaultHost("");

export const AUTH_SERVICE_URL =
  process.env.VUE_APP_AUTH_SVC || getDefaultHost("auth");
export const PRODUCT_SERVICE_URL =
  process.env.VUE_APP_PRODUCT_SVC || getDefaultHost("/api/product-svc");
export const ORDER_SERVICE_URL =
  process.env.VUE_APP_ORDER_SVC || getDefaultHost("/api/order-svc");
export const AUTH_REALM = process.env.VUE_APP_AUTH_REALM || "e-shop";
export const AUTH_CLIENT_ID =
  process.env.VUE_APP_AUTH_CLIENT_ID || "frontend-svc";
// Add other environment variables as needed
