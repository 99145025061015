<template>
  <div class="container">
    <NavigationBar />
    <!--    <img alt="Vue logo" src="./assets/logo.png" />-->
    <div
      style="font-size: 200%; font-weight: bold"
      id="logo"
      class="main-text-color"
    >
      <i class="bi bi-basket"></i> E-Shop
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
@import "@/assets/css/global.css";
</style>
<script>
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  name: "App",
  components: { NavigationBar },
  data() {
    return {};
  },
};
</script>
